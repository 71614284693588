import { isEmpty } from 'lodash';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isErrorUserPendingApproval, isForbiddenError, storableError } from '../../util/errors';
export const ASSET_NAME = 'landing-page';
export const recommendedSectionId = 'recommended-listings';

// ================ Action types ================ //

export const FETCH_ASSETS_SUCCESS = 'app/LandingPage/FETCH_ASSETS_SUCCESS';
export const GET_USER_REQUEST = 'app/LandingPage/GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'app/LandingPage/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'app/LandingPage/GET_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  recommendedListingIds: [],
  reviewIds: [],
  coaches: [],
  getUserError: null,
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_REQUEST:
      return {
        loading: true,
        getUserError: null,
        ...state,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        coaches: payload.data,
        loading: false,
        getUserError: null,
      };
    case GET_USER_ERROR:
      console.error(payload);
      return {
        ...state,
        loading: false,
        getUserError: payload,
      };
    case FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        recommendedListingIds: payload.recommendedListingIds,
        reviewIds: payload.reviewIds,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchAssetsSuccess = (payload) => ({
  type: FETCH_ASSETS_SUCCESS,
  payload,
});

export const getUsersRequest = searchParams => ({
  type: GET_USER_REQUEST,
  payload: { searchParams },
});

export const getUsersSuccess = data => ({
  type: GET_USER_SUCCESS,
  payload: { data },
});

export const getUsersError = e => ({
  type: GET_USER_ERROR,
  error: true,
  payload: e,
});

export const getRecommendedListingParams = (config, listingIds) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;

  return {
    ids: listingIds,
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'price',
      'deleted',
      'state',
      'publicData.transactionProcessAlias'
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };
};

/**
 * Fetch coaches against their given user IDs
 * @param {string[]} searchParams
 * @param {*} config
 */
export const getUsers = (searchParams, config) => async (dispatch, getState, sdk) => {
  dispatch(getUsersRequest(searchParams));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const getUserAPI = (userId) =>
    sdk.users
      .show({
        id: userId,
        include: ['profileImage'],
        'fields.profileImage': [
          'variants.scaled-small',
          'variants.scaled-medium',
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      })
      .then(response => response.data)
      .catch(e => {
        const error = storableError(e);
        dispatch(getUsersError(error));
        if (!(isErrorUserPendingApproval(error) || isForbiddenError(error))) {
          throw e;
        }
      });
  if(searchParams != undefined){
    const promise = await Promise.all(searchParams.map(getUserAPI)).catch(e => {
      const error = storableError(e);
      dispatch(getUsersError(error));
      if (!(isErrorUserPendingApproval(error) || isForbiddenError(error))) {
        throw e;
      }
    })
    dispatch(getUsersSuccess(promise));
    return promise;
  }
  return null

}

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  return dispatch(fetchPageAssets(pageAsset, true)).then(assetResp => {
    // Get listing ids from custom recommended listings section
    const payload = {
      recommendedListingIds: filterSection(assetResp, recommendedSectionId),
    };

    if (!isEmpty(payload)) {
      dispatch(fetchAssetsSuccess(payload));
    }
  });
};

function filterSection(assetResp, targetId) {
  const customSection = assetResp.landingPage?.data?.sections.find(
    s => s.sectionId === targetId
  );
  return customSection?.blocks?.map?.(b => b.blockName);
}
